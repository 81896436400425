<template>
	<div class="account-member">
		<div class="center" style="font-size:1.5em; font-weight:bold;">
			<router-link to="member">金粉會員</router-link>｜
			<router-link to="donor">椅仔會員</router-link>｜
			<router-link to="volunteer">金枝志工</router-link>
		</div>
		<br>
		<br>
		<router-view />
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
}
</script>

<style lang="scss" scoped>
.router-link-active {
	color: $theme;
	text-decoration: underline;
}
</style>